.container-indicador {
  position: relative;
  width: 100%;
  height: 300px;
}

.container-indicador > canvas {
  width: 100%;
  height: 300px;
}
